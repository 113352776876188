import React from "react"
import Box from "./box"
import Text from "./text"

const Count = (props) => {
  const { children, ...restProps } = props
  return (
    <Box
      as="span"
      bg="muted"
      px="0.2em"
      py="0.125em"
      borderRadius="sm"
      display="inline-flex"
      alignY="center"
      {...restProps}
    >
      <Text as="span" color="text" bold mono size="0.8em" lineHeight="1">
        {children}
      </Text>
    </Box>
  )
}

Count.propTypes = {}

export default Count
